import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageHeader,
  BoxIntroDecor,
  BoxPoster,
  Poster,
  SectionHeader,
} from "../components/styles"

import Grid from "@material-ui/core/Grid"

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "index/poster.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 488, maxHeight: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Grid container style={{ margin: "0 0 84px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <SectionHeader>INTERNATIONAL FILM + MEDIA PRODUCTION</SectionHeader>
            <PageHeader variant="h1">
              <span>Film from an</span>
              <br />
              unseen, diverse
              <br />
              <br />
              <span>PERSPECTIVE</span>
            </PageHeader>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.poster.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Index
